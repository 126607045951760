@import "../../Layout/common";
/*
 * Styles for FLEX layout of ReactTable 7
 * (see main.scss for shared .table styles)
 */

//.table-container{
//  position:relative;
//  min-height:400px;
//  .table-loader{
//    position:absolute;
//    display:block;
//    left:0;
//    right:0;
//    top:0;
//    bottom:0;
//    background: rgba(255, 255, 255, 0.8);
//    -webkit-transition: all .3s ease;
//    transition: all .3s ease;
//    z-index:1;
//  }
//  .table-loader > div {
//    position:absolute;
//    width:100%;
//    font-size: 15px;
//    color: rgba(0, 0, 0, 0.6);
//    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
//    top:20vh;
//    left:49vw;
//    text-align:inherit;
//    display:inline-block;
//    -webkit-transform: translateY(50%);
//    transform: translateY(50%)
//  }
//}

.flex-table {
  display: block;
  background-color:#fff;
  border-spacing: 0;

  .thead {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;

    .thr {

      .th{
        text-align:left;
        position: relative;
        border: none!important;
        font-weight: 700!important;
        font-size: .9rem!important;

        white-space: break-spaces!important;
        word-wrap:normal;

        &.sortable{
          //allow space for sort-icon at right
          padding-right:18px;
        }
        &.asc {
          box-shadow: inset 0 3px 0 0 $info!important; // sorted column emphasis line (top)
        }

        &.desc {
          box-shadow: inset 0 -3px 0 0 $info!important;// sorted column emphasis line (bottom)
        }

        .sort-icon {
          position: absolute;
          top: 8px;
          right: 4px;
          color: $gray-500;

          &.sort-active {
            color: $info; //sort direction arrows
          }
        }
        &.numeric {
          text-align: right;
        }
      }

    }
  } //thead

  .tfoot{
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    font-weight:600;
    .td {
      text-align: right;
    }
  }

  .tr {
    display: block;
    &:last-child {
      .td {
        border-bottom: 0;
      }
    }
    &:hover{
      //background: rgba(223, 249, 249, 0.2)!important;
      background-color: $table-hover-bg!important;
      box-shadow: 0px 8px 8px -8px rgba(184, 222, 249, 0.5);
    }
  }


  .th, .td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    //background-color:$content-bg;
    // font-weight: 300!important; //CP: conflict w global default 400
    //padding: 7px 10px!important;

    //white-space: nowrap; //may need to be initial
    //text-overflow: ellipsis;
    //overflow: hidden; //may need to make visible

    overflow:visible!important; //not auto.
    white-space:initial!important;  //override react table no-wrap, don't truncate

    position: relative;

    &:last-child {
      border-right: 0;
    }

    .resizer {
      display: inline-block;
      // background: blue;
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;

      //  &.isResizing {
      //    background: red;
      //  }
    }
  }

  &.striped {
    .odd {
      background-color: rgba(0, 0, 0, 0.01)
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;

    .label{
      // display: flex;
      // justify-content: space-between;

      span{
        padding: 0 .2rem;
      }
    }
    input {
      // width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: 400;
      outline-width: 0;
    }
    button {
      display: block;
      width: 30%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.1s ease;
      transition: all 0.1s ease;
      cursor: pointer;
      outline-width: 0;
    }
  }

  /*
 Sub-component row styles
 When a row has an expander than opens a row containing a subcomponent.
 (component could be another table, or anything)
 */
  .subcomponent{
    position:relative;
    background-color: #ebeff3;// #fafcfd; //#ebeff3
    // padding:1rem 2rem;

    //-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
    //-moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
    //box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
    -webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);
    -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);

    .pointer{
      position:absolute;
      top:0;
      left:calc(50% - 16px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 16px 0 16px;
      border-color: #ffffff transparent transparent transparent;
      z-index:1005
    }

    .title{
      color:$gray-600;
      font-weight:700;
      display:block;
    }

    .table-container {
      min-height: auto;
      //margin:0.4rem 2rem 0 2rem;
      margin-top:0.4rem;
      .table{
        background-color: #fff;//$gray-100;
        border:1px solid $gray-300;
        border-radius:6px;

      }

    }
  } //.subcomponent
} //.flex-table



