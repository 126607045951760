@import "../../Layout/common";

.icon-private-channel {
  color:red;
  margin-left:-16px;
  margin-right:5px;
}

.source-links {
  font-size:0.8em;
}

.channels-table{

  //.sources-subtable-container{
  //  margin-left:40px;
  //  font-size:.9em;
  //
  //}
  //.sources-subtable{
  //  background-color:#f9f9fd;
  //  border-left:10px solid #f0f0f7;
  //}

  .td.sources-link svg{
    color:$gray-600;
  }

  //.sources-subtable-container{
  .channel-sources{

    .pointer{
      left:100px!important; // middle: calc(50% - 16px);  //override of subcomponent pointer
    }


    .channel-source-detail{
      background-color: $white;
      width:80%;
      margin:0.5rem auto 0 0;
      .channel-source-row{
        padding:0.3rem 0.2rem;
        border-bottom:1px solid $gray-200;
        .source-status{
          display:inline-block;
          background-color: $gray-600;
          color:$white;
          border-radius: 4px;
          padding:0.1rem 0.3rem;
          font-size:0.8rem;
          font-weight:700;
          margin-left: 1rem;
        }
        .cs-cell {
          flex-basis: 13%;
          &.name {
            flex-basis:50%;
          }
          &.distro {
            flex-basis: 25%;
          }
        }
        &:last-child{
          border-bottom:none;
        }

        &.add-source{
          display:flex;
          margin-top:1rem;
          border:1px solid $gray-400;
          border-radius:4px;
          padding:0.5rem;
          background-color: #f3f6fa;
        }
      }

    }
  }

  //Deprecate
  .sources-subtable{
    //padding:0.6rem;
    margin:0.4rem 2rem 0 2rem;
    background-color: #fff;//$gray-100;
    border:1px solid $gray-300;
    border-radius:4px;

  }
}


#source-vendor-modal .modal-body {
  //min-height:92vh
}
.sources-table{
  .bid-margin{
    text-align:right;
  }
  .source-channels-cell {
    .source-channels{
      font-size: 0.8rem;
    }
  }
}

.source-form{
  .phone-number-list{
    max-height: 10em;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    li{
      list-style: none;
    }
  }

  .click-tag-form {
    background-color: #fafcfd !important;
  }

  .required-fields {
    .add-field-form {
      background-color: $body-bg;
    }
  }

}

// In source detail
.source-vendors {
  position:relative;
  .table-container {
    //border:1px solid pink;
    //position:relative;
    //display:flex;
    //flex-direction: column;
    //width:100%;
    //flex:1 1 auto;
  }

  .source-vendor-edit-overlay {
    position:absolute;
    top:0;
    left:1rem;
    right:1rem;
    z-index:50;
    width:100%;
    height:100%;
    min-height:330px;
    background-color:$white;
    margin:1rem;
    padding:1rem;
    border-radius:6px;
    border:1px solid $gray-300;

  }
}

.phone-numbers-table{
  .pretty-seed pre{
    font-size:0.8rem;
    color:$gray-600!important;
    padding:0.5rem;
  }
}
#phone-number-modal{
  .form-text{
    padding-top: calc(0.475rem + 1px);
    padding-bottom: calc(0.475rem + 1px);
    margin:0;
  }

  //.modal-content-background {
  //  overflow-y:auto;
  //}


}

#edit-vendor-modal .modal-content, #vendor-modal .modal-content{
  min-height: auto;
}
#edit-vendor-modal .modal-content #key::placeholder{
  color:$gray-700 !important;
}

/*

#publisher-list-links{

  margin-bottom:16px;
  .nav-item{
    color: #777;
    font-size:20px;
    cursor:pointer;
    margin:0 6px;
    border:1px solid #ccc;
    border-radius:5px;
    .nav-link{
      color:#777;
      border:none;
      background-color:#eeeeee;
      &.active{
        background-color:#c5def3;
        color:#3065E6;
      }
    }
    span{
      vertical-align:middle;
      margin-right:10px;
    }

  }
}
*/

