@import "../../Layout/common";
/*row of icons indicating various rule states*/
.rule-tree-icons{
  display:inline-block;
  color:$secondary;
  cursor: pointer;
  margin:0 4px;
  svg{margin-left:4px;outline:none;}
  .bid_blocked{color:#990000;}
  //routing rules
  .sell_strategy{color:orangered;}
  .sale_match_caps{color:firebrick;}
  .flow_control{ color: #f17ee4;}
  .payout{ color: green;}

  //advertiser rule icons (in tree)
  .bid_schedule{color:dodgerblue;}
  .match_conditions{color: #840df5;}
  .budget{color:green;}
  .tags{ color:#b5a02e;}
  .ads{ color:#534CA9;}
  .datasheets{ color: #cb7c04;}
  .prefill{ color: #b33a99;}
  .channel_modifiers{ color:#b5a02e;}
  .proxy_bidding{ color: #f17ee4;}
  .tracking{ color:navy;}
  .advanced{ color:darksalmon;}
  .post_sale{color: #8db77f;}
  .sale_confirmation{color: #8db77f;}

  //displayed in an entity listing (source, vendor, channel), as tags in a table cell
  &.table-rule-icons {
    margin-top:-4px;
    .table-tag{

      display:inline-block;
      background-color:$gray-100;
      border:0.5px solid $gray-300;
      padding:4px;
      font-size:0.8rem;
      border-radius:8px;
      margin-top:4px;
      margin-right:4px;
      white-space: nowrap;
      div:first-of-type {
        display:flex;
        flex-wrap:nowrap;
        align-items: center;
      }
      svg {
        margin-right:4px;
      }
      .label {
        color:$gray-700;
      }
      &:hover {
        background-color:$white;
      }

    }
  }


}
