@import "../../../../Layout/common";

.rule-search-page {
  .rule-search-container {
    min-height:400px;
  }

  .rule-search-result {

    box-shadow: $box-shadow-sm!important;
    padding:1rem;
    .title a {
      font-size:1.1rem;
    }
    .type-col {
      width: 80px;
      flex-shrink:0;
      .type {
        width: 80px;
        text-align:center;
        border-radius:6px;
        font-weight:700;
        color:$white;
        border:1px solid $gray-400;
        padding:0.25rem 0.25rem;
        &.order {
          background-color:$blue-400;
        }
        &.vendor {
          background-color:$orange-400;
        }
        &.source {
          background-color:$green-400;
        }
        &.channel {
          background-color:$red-400;
        }
      }
      .count {
        color:$gray-700;
        font-weight:600;
        margin-top:1rem;
      }
    }
    .rule-matches {
      //background-color: greenyellow;
      .match {
        //border:1px solid $gray-400;
        //background-color: $gray-100;
        padding:1rem;
        &:hover {
          background-color: rgba($gray-100, 0.35);
        }
        .expression {
          white-space: pre-line;
          word-break: break-word;
        }
      }
    }

    .targeting-summary{
      //flex-basis:400px!important;

      ul{margin-bottom:0;}
      .target-description{
        line-height:1.4rem;
        margin-bottom:0.25rem;
        svg{color: $gray-500;}
        .target-summary-label{
          background-color: $gray-100; //#deffd9;
          padding:1px 3px;
          border:1px solid #ddd;
          &.highlight {
            background-color: rgba(#0eef13,0.5); // #faee1f;
          }
        }
        .target-match-bool{font-weight:500;}
        .target-is{color:#000099;}
        .target-is-not{color:#990000;}

      }

      #expression-toggler{
        position:relative;
      }
      .target-summary-group-or{
        //margin-left:50px;
        color:$gray-600;
        margin:2px 0;
        font-weight:500;
      }
      h5{font-size:1em;}
    }
  } //.rule-search-result
} //.rules-search-page

