@import "../../../Layout/common";


.dynamic-editor {
  .col-6 {
    flex-grow: 1;
    transition: all 300ms ease;
  }

  .col-2 {
    //width: 0;
    flex-shrink: 1;
    transition: all 300ms ease;
  }
  .row [class*='col-'] {
    transition: all 0.3s ease-in-out;
  }
  .preview-col {

  }
  .edit-col {
    border-right:1px solid $gray-300;
  }

  #btn-expand-editor {
    padding-left:0;
    padding-right:0;
    margin-right:-22px;
    margin-top:24px;
    background:#fff;
  }
}

.edit-col{

  position:sticky;
  top:62px; //about the height of the site's sticky header

  // Firefox needs this for sticky to work inside flex
  align-self: flex-start;
  height: 100%;



  .h-auto-adjust{

    height: auto; //will be set by script
    transition:height 500ms;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 60px 1fr auto;
    grid-gap: 2px;
  }



  .mastodon-tabs{
    border-bottom: 1px solid $gray-400;
  }

  .editor-scroll-pane{

    overflow-y: auto;
    overflow-x:hidden;
    padding-right: 6px;
  }

  .bottom-content {
    border-top:2px solid $gray-300;
  }
  .collapsible {
    .title {
      padding:0.5rem;
      background-color: $gray-100;
      border:1px solid $gray-300;
      border-radius:4px;
      margin:1em 0;
      h5{margin-bottom: 0;}
    }

  }
}

.preview-col {

}

.integration-routing {
  .badge {
    &.source {
      background-color: rgba($green-600, 0.7)!important;
      border-color: $green-700;
    }
    &.channel {
      background-color: rgba($red-600, 0.7)!important;
      border-color: $red-700;
    }
    &.vendor {
      background-color: rgba($blue-600, 0.7)!important;
      border-color: $blue-700;
    }
    &.channel_eligibility {
      background-color: rgba($purple-600, 0.7)!important;
      border-color: $purple-700;
    }
  }
}


