@import "../../Layout/common";

.enhanced-select{
  &.is-invalid {
    div {
      border-color: $danger;
    }
    .invalid-feedback {
      display:block;
    }
  }
  // Custom styles for react-select
  .enhanced-select__control{
    min-width:130px;
    border: 1px solid $gray-300;
    border-radius: 4px;
  }
  //bootstrap seems to put z-index:4 on inputs in error state which can cause this menu to go under them
  .enhanced-select__menu { z-index: 5}

  .enhanced-select__option { padding-left:1.2em}
  .enhanced-select__option--is-selected {
    color:$black;
    background-color:$gray-100;
    //check mark before selected
    &:before {
      content:  "\2713";
      position:absolute;
      left:2px;
      font-weight:bold;
    }
  }

  //.enhanced-select__option--is-disabled {
  //
  //}

  // When we use formatOptionLabel for customized option display, the default
  // display style in the control (where max-width:calc(100%-8px)) can cause flex divs
  // to squish instead of take up the entire width
  .enhanced-select__single-value {
    width: calc(100% - 8px);
    max-width: unset;
  }

  .enhanced-select__clear-indicator{
    cursor:pointer;
  }

  .invalid-feedback {
    display:none;
  }

}
