.ad-display{
  display:flex;
  max-width: 700px;
  font-size:0.85rem;
  padding:0.5em;
  white-space: initial; //overrides ReactTable cell white-space nowrap
  background-color:#fff;
  border-radius:4px;
  .ad-display-img{
    flex-basis:180px;
    flex-wrap:nowrap;
    flex-shrink:0;
    img{
      width:180px;
      height:auto;
      /*margin-top:8px;*/
      padding: .25rem;
      background-color: #fff;
     // border: 1px solid #dee2e6;
     // border-radius: .25rem;
      max-width: 100%;
    }
  }
  .ad-display-body{
    margin-left:12px;
    h5{
      font-size:1.2em;
      font-weight:bold;
    }

    .ad-display-description{
      margin:6px 0;
      p{margin:0}
      ul{
        margin:0 0 0 16px;
        padding-left:0;
        list-style-type: disc;
        li {
          margin:0;padding:0;

        }
      }
    }

    .ad-display-url{
      a,a:visited, a:link {
        color: green;
        cursor:pointer;
      }
    }
  }



  .ad-display-quote-button{
    width:180px;
  }
}
