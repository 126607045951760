@import "../../Layout/common";

.dropzone {

  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border:1px solid $gray-400;
  border-radius:4px;
  background-color: #fafafa;
  outline: none;
  transition: border .24s ease-in-out;

  &:focus{
    border-color: #2196f3;
  }

  &.drag-active {
    border-color:#2196f3;
  }

  &.drag-accept {
    //border-color:green;
    border:2px solid green;
  }

  &.drag-reject {
    border-color:darkred;
    border-width:2px;
    .file-icon{
      color:darkred;
      .file-border{
        border-color:darkred;
      }
    }
  }

  .file-icon{
    margin-right:2rem;
    color:$gray-400;
    border-right:1px solid $gray-400;
    .file-border{
      display:flex;
      align-items: center;
      min-width:80px;
      min-height:80px;
      margin:0.5rem;
      border-radius:4px;
      padding:0.5rem;
      border:4px dashed $gray-400;
      text-align:center;
      transition: border .24s ease-in-out;

    }
  }
  .rejected-file svg{
    color:darkred;
  }
}
