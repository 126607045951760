@import "../../Layout/common";

  #report-filters{
    max-width:400px;
  }

  #reports-groupby-dropdown{
    min-width:300px!important;
  }
  #reports-compare-dropdown{
    min-width:350px!important;
  }
  #view-columns-dropdown{
    min-width:800px!important;
    .select-filter__option { padding-left:1.2em}
    .select-filter__option--is-selected {
      color:$black;
      background-color:$gray-100;
      //check mark before selected
      &:before {
        content:  "\2713";
        position:absolute;
        left:2px;
        font-weight:bold;
      }
    }
  }

  #reports-groupby-dropdown, #reports-compare-dropdown, #view-columns-dropdown{

    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);

    //So open menu stays within the dropdown
    .select-filter__menu {
      position:relative;
      box-shadow:none;
      border-size:1px;
    }

  }



#report-spinner {
  text-align:center;
  padding:2rem;
}



  .reports-table{
      .delta-icon{
        margin-top:-4px;
      }

    // Some users have permission for "expanded" reports that produces additional columns.
    // This causes the columns to horizontally overflow the layout.
    // Fix: overide overflow:visible.
    .table {
      overflow-x:visible;

    }

    .tr{
      &.selected{
        background-color: rgba(250, 248, 155, 0.5) !important;
      }
    }

    .th{

      &.account_name{
        min-width:200px;
      }
      &.pivot-header{
        border-bottom:1px double $gray-200;
        background-color:#ebf2f7;
        text-align:left!important;
        &.even{

        }
        &.odd{
          background-color:#d2dbe6;
        }
      }
      //remove nowrap from header cell (causes longer header text to be cut off)
      white-space: initial;
      .help-popper-icon{
        margin-left:0.25rem;
      }
    }

    //TODO: how to work this into react table 7
    .thead .tr, .tbody .tr, .tfoot .tr{ // old table v6
      //Darker vertical column border between each pivot section
      .first-pivot-col:not(:first-child){
        border-left:1px solid $gray-300
      }

    }

    .td{
      //small number cells can look a little cramped
      min-width:100px; //set min width only on content cells not header cells
      &.auction_id, &.lead_id{
        //UUID cells
        white-space: nowrap!important;
       text-overflow:ellipsis;
        overflow:hidden!important;
      }


    }



    //colors for delta header, cells
    .th, .td{

      &.numeric{
        .rpt-header{ justify-content: flex-end !important }
      }

      &.delta{
        background-color: rgba(235, 252, 235, 0.30);
        .negative{
          color:red;
        }
        .positive{
          color:darkgreen;
        }
      }

      .row-actions{
        display:flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        min-width:80px;
        .btn{
          margin:0 0.2rem;
        }
      }


    }

    tfoot {
      td {
        text-align: right;
        font-weight:600;
        &.text{
          text-align:left; //"Total" label
        }
      }
    }


  }


//Prompt that warns of long load times, overlays table
#load-warning-container{
  position:absolute;
  top:0;left:0;
  right:0;bottom:0;
  background: rgba(255, 255, 255, 0.8);
  z-index:1000;

  .load-warning{
    position:absolute;
    min-width:400px;
    top:100px;
    left:calc(50% - 200px);
    border:1px solid $gray-400;
    border-radius:6px;
    padding:1rem;
    background-color:$gray-100;
    z-index:1001;
  }
}

#default-report-links{
  .default-report-row{
    margin-bottom:1rem;
    .report-name{
      flex-basis:14rem;
    }
    .btn{
      padding-top:0;
      passing-bottom:0;
    }
  }
}

//Modal with detail view of a lead or call
.modal-prospect-detail{
  background-color:#fcfdfd;
  font-size:0.9rem;
  .modal-header{
    .modal-title .subheader{
      color:$gray-600;
    }
    .detail-control{

    }
  }

  .modal-body{
    min-height:400px;
    padding-top:0;
    legend {
      font-size:1.2em;
      color:$secondary;
      font-weight:700;
      border-bottom:1px solid cornflowerblue;
      display:flex;
      justify-content: space-between;
    }

    .contact {
      svg{
        color:$gray-500;
      }
    }

    .detail-table{
       margin-left:1em;
       .detail-row{
         display:flex;
         border-bottom: 1px solid #fafafa;
         label{
           flex-basis:170px;
           font-weight:600;
           color:$gray-700;
           display:block;
           margin-right:2em;
         }
         &:last-child{ border-bottom: none;}
       }

       h5{font-size:1rem;font-weight:600;color:$gray-700}
       //.driver, .vehicle {margin:}
     }
  }

}



