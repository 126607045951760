@import "../../Layout/common";

.key-value-builder{
  .key{
    flex-basis:40%
  }

  .value{
    flex-basis:60%;
    .object-value{
      background-color: $white;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .input-group-prepend .btn{
      border: 1px solid #ced4da;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  .CodeMirror{
    height:auto;
    min-height:4rem;
    border:1px solid #ced4da;;
    margin-top:0.25rem;
    border-radius:0.25rem;
  }

  .key-value-equals{
    padding-top:0.45rem;
  }
}
