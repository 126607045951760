@import "../../Layout/common";

/* Styles for SeedDataBuilder Component */
.seed-data-builder{
  //if we want a scrolling section
  // max-height:400px;
  //overflow-y:auto;

  .key{
    flex-basis:40%
  }

  .value{
    flex-basis:60%;//420px;
    .object-value{
      //padding:0.5rem;
      background-color: $white;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .input-group-prepend .btn{
      //style "light"
      border: 1px solid #ced4da;
      //border-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  .CodeMirror{
    height:auto;
    min-height:4rem;
    border:1px solid #ced4da;;
    margin-top:0.25rem;
    border-radius:0.25rem;
  }

  .key-value-equals{
    padding-top:0.45rem;
  }

  .pretty-seed pre{
    padding:0.5rem;
  }
}


