@import "../Layout/common";

.mst-data-row {
  font-size:0.9rem;
  display: flex;


  label {
    font-weight: 600;
    color: $gray-700;
    margin-right: .5rem;
    //min-width: 150px;
  }

  // By default rows are inline (label next to value horizontally)
  // 'field' layout puts label on top of value as in a form field
  &.field {
    flex-direction: column;
  }

  &.compact {
    label {
      min-width:80px;
      padding-bottom:0;
      padding-top:0;
      white-space: nowrap;
    }
  }


  div:last-child{ //data value
    flex-grow:1;
    word-break:break-word;
  }


}

// on smaller breakpoints force it to 'field' mode to save save
@include media-breakpoint-down(lg) {
  .mst-data-row {
      flex-direction: column;
  }
}
