@import "../../Layout/common";

.alert-targeting-errors{
   ul{
     margin:8px 0 0 0;
   }
 }

// Border-left
// Blue (groups): #73b3f3 , orange (targets):  #F5E2B8


  .group-or{
    margin: 1em;
    font-weight:bold;
    font-size:1.2em;
    text-align:center;
    width:50px;
    border-radius:5px;
    color:#fff;
    background-color:$gray-300;
  }



  .target-group{

    border-left:8px solid $gray-300;
    //&:hover{
    //  border-left-color:#73b3f3;
    //}
    background-color:$white;
    margin: 0;
    padding:1em 0 2em 1em;
    &:first-child{
      padding-top:0;
    }

    .btn-trash{ color:$gray-500;}

    //.delete-prompt{
    //  background-color: $ivory;
    //  border:1px solid $gray-400;
    //  padding:0.15em 1em;
    //  font-size:0.9rem;
    //  font-weight:400;
    //  //color:$gray-600;
    //    i{ margin-right:1em;}
    //    .btn{ padding:0 0.5rem;}
    //
    //}

    .target-group-header{

      line-height:1.4em;
      color:$gray-600;
      margin-bottom:6px;
      .group-name{
        font-weight:bold;
        font-size: 1.2em;
      }
      .group-target-count{
        font-size:0.8em;
      }


    }


    .group-targets-container{
      display:flex;
      flex-direction: column;
      align-items: stretch;



      .target-container{
        //border-left:8px solid $gray-400;
        border-left:2px solid $gray-400;
        border-radius:12px;
        //&:hover{
        //  border-left-color:#F5E2B8;
        //}
        padding:1em 0 1em 1em;
        background-color:$white;
        &:first-child{
          padding-top:0;
        }

      }

      .target-and{
        //margin-left:-8px;
        margin:0.3em 0.7em;
        font-weight:bold;
        font-size:1.2em;
        text-align:center;
        /*margin:12px 0;*/
        width:50px;
        border-radius:5px;
        color:#fff;
        background-color:$gray-400;
        //margin:10px auto;
      }

      .target-header{
        font-weight:600;
      }
    }

    //.target-container:last-child{
    //  .target-form{ border-bottom:none;}
    //}



   // target-form was here

} //target-group


.target-form{
  position:relative;
  //padding:1em;
  //margin:1em 0;

  .target-type-select{
   // z-index:25000;//asdf
    min-width:200px;
  }
  //.target-matches-btn{
  //  flex-basis:150px;
  //}
  .target-form-values{
    min-width:200px;
    .target-value-form .row{margin-left:0;}
    .small{font-size:0.8rem;}
  }

  .alert{
    color:#333;
    .fa{color:red;}
  }
  //legend {
  //  color:$gray-600;
  //  font-size:1rem;
  //  margin-bottom:0!important;
  //  font-weight:bold;
  //  border-bottom:none!important;
  //  flex-shrink:2;
  //  flex-basis:120px;
  //  width:auto;
  //  min-width:auto;
  //}
  .form-check{
    //padding-left:0;
    justify-content:flex-start;
    .form-group{
      margin-right:12px;
    }
    .form-check-input{
      margin-right:4px;
    }
  }

  //second row of form (target,accept unknown)
  .form-data-settings{
    margin-top:10px;

    //inline radio button group
    .form-check{
      .form-group{
        margin-bottom:0;
      }
      .formgroup-label{
        font-weight:600;
        margin-right:1em;
      }
      .form-group label{
        font-weight:400;
        font-size:0.9em;
      }
    }


  }

  .target-value-options-card{
    width:220px;
    max-width:400px;

    .form-check-input[type="checkbox"] {
      margin-right: 0.5rem;
    }

    .batch-check{
      border-bottom:1px solid $gray-200;
    }
    .card-body{
      max-height:181px;
      // overflow-y:scroll; interferes with reactselect dropdown
      overflow-y:auto;
      padding:6px;
      .form-group{
        margin-bottom:0;
        .form-check-input{
          position: inherit;
          margin-left:0;
          margin-right:8px;
        }
      }
    }
  }

  //react-select widget
  .multi-choice-select{
    min-width:200px;
  }

  .target-match-bool{
    font-weight:bold;
    font-size:1em;
    text-align:center;
    /*margin:12px 0;*/
    width:42px;

    padding:3px;
    border-radius:5px;
    color:#fff;
    background-color:#b6b6b6;
  }

  .target-is{
    background-color:#60ad30;
  }
  .target-is-not{
    background-color:#f76969;
  }

  .alert{
    i { color:#f76969;vertical-align:middle}
    color:#333;
  }

  /* A two-button buttonGroup */
  .yes-no{
    .btn{
      font-weight:700;
    }

    .btn:focus{
      box-shadow:none;
      outline: none;
    }
    .btn-outline-success{
      /* YES */
      color: #1e6508;
      background-color: #4dbd7447;
      //border-color: #4dbd74;
    }

    .btn-outline-danger{
      /* NO */
      color: $red;
      background-color: #ffe6e6;
    }

    .btn-outline-secondary{
      /* OFF STATE */
      color:$gray-400;
      background-color: $white;
      border-color:$gray-500;
        &:hover{
          background-color: $gray-300;
          color:$gray-600;
        }
    }


  }

  //matches button (is/not)
  .btn-bool-widget{
    background-color: #f6f6f6;
    border-color:#cccccc;
    /*font-weight:bold;*/
    color:$gray-700;
    width:100px;
    margin:auto;
    padding:.375rem .375rem;
    &.btn-secondary:hover{
      background-color: #f6f6f6;
      color:$gray-700;
    }
    &.btn-secondary:focus:active{
      background-color: #f6f6f6;
      color:#000;
      box-shadow: none;
    }
    /*&:active{
      background-color: #f6f6f6!important;
      box-shadow: none;
    }
    */
    .bool-true svg{
      color:forestgreen;
    }
    .bool-false svg{
      color:#990000;
    }
    &.btn-success{
      .fa{color:green;}
      border-color:#ced4da;
      //background-color: #b9f4db;
      //background-color: #b9f4db;
    }
    &.btn-danger{
      .fa{color:$red;}
      border-color:#ced4da;
      //background-color: #ff8294;
      //background-color: #ff8294;
    }
  }
} //target-form

//styles based on rule type (advertiser or routing), set with className prop
.targeting-manager{
  &.advertiser{
    .target-type-select{
      min-width:275px;
    }
  }
}



