@import "../../../Layout/common";

.auction-page {
  .card-body{
    min-height:12rem;
  }

  .auction-detail{
    margin-top:2em;
  }
}


.auction-detail{
  min-height:300px;

  .overview{
    flex: 0 0 280px;
    background-color: #f1f3fc;//#f0f5ff;  ///Light gray: #f8f9fb;  //sky blue: #e4e8f3 //Light blue: #fcfdff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding:1rem;
    .ov-pod{
      display:flex;
      flex-direction: column;
      margin-bottom:1em;
      //color:$gray-600;
      .label{
        font-weight:700;
        //color:$gray-700;
      }

    }

    .count{
      position:relative;
      text-align: center;
      font-size:0.9rem;
      color:$gray-700;
      flex-basis:20%;
      background-color:#fff; //#e8ebf9;
      border-radius:4px;
      padding:4px;
      cursor: pointer;
      .number{
        color:$primary;
        font-weight:700;
        font-size:1.2rem;
      }
      svg{
        position:absolute;
        top:2px;
        right:2px;
        color:darkred;
      }
    }

  }

  .auction-subnav{
    flex: 0 0 80px;
    padding:0;
    background-color: #f5f7fd;
    border-left:0.5px solid #fff;
    align-items: center;

    .nav-item{
      margin-bottom:0.4rem;
      width:100%;
      cursor: pointer;
      &.active{
        background:$content-bg; //same as general background
        color: $cyan;
        .nav-link svg{color:$primary;}
      }
      .nav-link{
        text-align: center;
        color: $body-color;
        svg{color:$gray-500}
        &:hover svg {color: $gray-600;}

      }
    }
  }

  // dedupe this style with block reason
  .error {
    display:inline-block;
    padding:0.1rem 0.25rem;
    color:$white;
    background-color: rgba(203, 0, 0, 0.6);
    border-radius:4px;
    border:1px solid $gray-400;
  }

  //area for auction change, copy
  .tools{
    width:300px;
    text-align: right;
  }

  //Box enclosing a data section, (standard form-section with modifications)
  .form-section{
    //.form-section-header{color:$primary;}


    .toggle-me{
      overflow-x:auto;
      .data{
        display:none;
        &.show{
          display:block;
        }
        //if there are very long unbroken lines, helps to wrap <pre> with <code> to prevent overflow
        pre{
          background-color:#fdfdfd!important; //#f8fafb!important;
          border-radius:4px;
          padding:0.5rem;
          border:1px solid $gray-200;
          overflow-x:auto;
          white-space: pre-wrap;
          word-wrap:break-word;
        }
      }
    }
  }

  .lead-data {
    .desc-pill {
      padding:0.3rem 0.5rem;
      border:1px solid $green-300;
      background-color: $green-100; //$gray-100;
      border-radius: 4px;
      font-size:0.8rem;
      font-weight:bolder;
      color:$gray-700;
    }
    .delivery-pill {
      padding:0.3rem 0.5rem;
      border:1px solid $orange-300;
      background-color: $orange-100; //$gray-100;
      border-radius: 4px;
      font-size:0.8rem;
      font-weight:bolder;
      color:$blue-600;
      .btn-link {
        color:$blue-600;
      }
    }

  }

  .auction-matches{

    .match-filters {
      position:sticky;
      top:0;
      background-color: $white;
      padding:0.5rem;
      border-bottom:1px solid $gray-400;
      .form-group{
        margin-bottom:0!important;
      }
      .filter-count{
        margin-top:1rem;
        font-weight:600;
        color: $gray-600;
        font-size: 0.9rem;
      }
    }

    .match-container{
      margin-bottom: 2em;
      border:1px solid $gray-400;
      padding:1rem;
      .match{
        flex-basis:65%;
        padding-right:1rem;

      }

      .match-bid{

        flex-basis:35%;

        .bid {
          background-color: $gray-100;
          border:1px solid $gray-200;
          border-radius:4px;
          padding:0.5rem;
        }

        .row-divider {
          border-top-color:$white;
        }

      }

      .data-row, .mst-data-row {
        .sale{
          background-color:#F8FAFF;
          margin-top:4px;
          font-size:0.8rem;
          border:1px solid $gray-200;
          padding:0.5rem;

        }
      }

      .ads-container {
        overflow-x:hidden;
        // It would be nice to shrink ads this way,
        // but parent container remains actual width
        // transform: scale(0.5);
        // transform-origin: left;
      }

      .ad-display {
        margin:0.5rem 0;
        border: 1px solid $gray-200;

      }
    }
  }


  .block{
    background-color: #F8FAFF; //$white; //#f5f7fd;
    padding:0.5rem;
    border:1px solid $gray-300;
  }

  .bid-explanation {
    padding:1rem;
    background-color: $gray-100;
    border-radius:10px;
    border-left:5px solid $gray-200;
    ul {
      margin-bottom: 0 !important;
    }
  }

  .deliveries {
    .dropdown-item {
      &.selected  strong {
        color: $primary;
      }
    }

    .badge {
      &.match {
        background-color: $green-600!important;
      }
      &.prefill {
        background-color: $orange-400!important;
      }
      &.enrich {
        background-color: $primary!important;
      }

    }

    .deliveries-table-container {
      position:relative;
      .tr.selected {
        background-color: rgba($blue-200, 0.2);
      }
      &.scrolling {
        border-bottom: 1px solid $gray-400;
        .tbody {
          position:relative;
          max-height:200px;
          overflow-y:scroll;
        }

      }
    }
    .deliveries-table {
      .tr:hover {
        cursor:pointer;
      }
    }

    .col-deliveries {
      padding: 0;
      background-color: $gray-100;
      .delivery-item {
        cursor: pointer;
        padding:1em 0.5em;
        &.selected {
          background-color: $white;
        }

      }
    }
  }

  .delivery{
    .delivery-status{
      display:inline-block;
      padding:0.1rem 0.25rem;
      background-color: rgba(172, 236, 160, 0.98);
      border-radius:4px;
      border:1px solid $gray-400;
      &.failed{
        color:$white;
        background-color: rgba(203, 0, 0, 0.6);
      }
    }

  }

  .timeline {
    svg {
      color: $gray-500;
    }
  }
}


.modal-dialog.auction-detail{

  .modal-content{
    //min-height:500px;
    position:relative;
    height:90vh;
    overflow:hidden;



    .modal-body{
      height:100%;
      overflow:hidden;

      .auction-detail{
        position:relative;
        height:100%;
        min-height: auto;
        //TODO: no margin-top (using mt-4 for page view)
      }

      .auction-section{
        position: relative;
        height:100%;
        overflow-y:auto;
        margin-bottom:100px;
      }

    }

  }

}

.match-tree {
  .rule {
    border-radius: 3px;
    padding: 3px 5px;
  }
  .matched {
    background-color: $green;
  }
  .no-match {
    color: $gray-600;
  }
  .blocked {
    background-color: $red;
  }
}

.ivr-log td {
  vertical-align: top;
  padding: 10px;
}
