@import "../../Layout/common";
/*
 * STYLES FOR SHARED RULES COMPONENTS
 * shared between advertiser rules and publisher targeting rules
 */



// applied to .page-section in target rule manager
.rules-config-section{
  position:relative;
}


#move-rule-widget{

  width:100%;
 background-color: $white;

  .btn{
    margin:0;
  }
  #move-rule-placement{
    &.form-group{
      margin-bottom:0.5rem;
    }
    .btn-group-vertical{
      width:100%;
      .btn{
        border:1px solid $gray-400;
        background-color: $gray-200;
        color:$gray-500;;
        &.active{
          background-color: $white;
          color:$primary;
          font-weight:$font-weight-bolder;
        }
        &:focus{
          outline:none;
          box-shadow:none;
        }
        &:hover{
          color:$gray-600;
        }
        &:first-child{
          border-bottom:none;
        }
        &:last-child{
          border-top:none;
        }
      }
    }
  }
  #rule-choice-container{
    margin-top:1rem;
    padding-top:0.5rem;
    border:1px solid $gray-400;
    max-height:400px;
    overflow-y:scroll;
  }
  .dropdown-item{
    border-bottom:1px solid #fff;
    padding-left:8px;
    cursor:pointer;
  }

  .rule-branch{
    margin-left:8px;
    .rule-branch-line{
      border-left:1px dotted #666;
      margin-left:2px;
      padding-left:8px;
    }
  }
}

/* column with rule tree */
.col-rule-tree {

  position:relative;
  width:432px;
  min-width:432px;

  &.closed{
    margin-left:-400px;
    opacity:0;
  }

  .filter-result{
    border-bottom:1px solid $gray-600;
    margin:1rem 0 0.5rem 0;
    font-weight:600;
    color:$gray-700;
  }



  /* the rule container extending from the branch */
  .tree-leaf{
    position:relative;
    width:100%;
    display:flex;
    &.root{
      .icon-outer{
        display:none;
      }
    }


    /* Bid display & entry for rule */
    .rule{
      /* pill with rule bid at far right */
      .rule-bid{
        position:relative;
        width:60px;
        flex-basis:60px;
        padding:2px 4px;
        color:white;
        background-color: #699DD1;
        border-radius:4px;
        font-weight:bold;
        font-size:0.75em;
        vertical-align:middle;
        text-align:center;
        cursor: pointer;

        &.bid-modifier, &.bid-margin{
          background-color: #269c4c;
          // no radius, split with arrow
          flex-basis:40px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.bid-block{
          background-color: #990000;
        }

        &.zero-bid{
          background-color: #990000;
        }

        &.bid-pass{
          /*display:none;*/
          background-color: transparent;
          border:1px solid $gray-200;
        }
      }

      .bid-display{
        width:60px;
        height:22px;
        position:relative;

        .exclusive-star {
          // icon overlaps over top right corner of bid display
          position: absolute;
          top: -7px;
          right: -5px;
          z-index: 500;
        }
      }

      /* for bid modifiers, .rule-bid is split with up/down arrow on left */
      .bid-modifier-arrow {
        vertical-align:middle;
        width:20px;
        flex-basis:20px;
        text-align:center;
        padding:2px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color:limegreen;
        color:#fff;
        &.sub-100{
          background-color:#ab7070;
        }
      }

      /* edit mode of rule-bid */
      .rule-bid-input{
        width:60px;
        input {
          height:22px;
          line-height:18px;
          padding:1px;
          font-size:0.8em;
        }
      }

      .exclusive-star{
        color:gold;
        &.optional{
          color: $gray-200;
          path{
            stroke:darkgreen;
          }
        }
        path{
          stroke:red;
          stroke-width: 20;
        }
      }

      .exclusive-warning{
        font-size:0.8rem;
        color:$gray-600;
        margin-top:6px;
      }
      .bid-edit-help{
        color:$gray-600;
        font-size:0.8rem;
      }
      .bid-warning{
        font-size:0.8rem;
        .value-delta{

          &.overflow{
            color:darkred;
            font-weight:600;
          }

        }
      }


    }  /* .rule */
  }


  .icon-outer{
    color:$primary;
    width:12px;
    margin-left:12px;
    padding-top:7px;
    white-space: nowrap;
    &.disabled {
      color:$gray-300;
    }
    .icon-folder{
      cursor:pointer;
    }
  }

  /* hack for horizontal branch lines: Make the first leaf transparent, all nested leaves visible*/
  .leaf {
    &::before {
      border-color:transparent
    }
  }
  .leaf  .leaf{
    &::before {
      border: solid $gray-400;
      border-width : 0 0 1px 1px;
    }
  }
  /* end hack */



  .leaf {
    //margin-left:6px;
    position:relative;
    border-left:1px solid $gray-400; //vertical line for entire "branch"

    .rule-container{
      &.root {
        .icon-outer {
          margin-left:0;
        }

      }


    }

    &::before{
      content      : '';
      display      : block;
      position     : absolute;
      top          : 0;
      left         : -1px;
      width        : 12px;
      height       : 18px;
    }
    &:last-child {
      border-color:transparent;
    }
  }


  .rule-branch{
    width:auto;
    position:relative;
    margin-left:16px;
    height:100%;
    opacity:1;

    &.closed{
      max-height:0;
      height:0;
      opacity: 0;
      visibility: hidden;
    }
    &.disabled{
     // background-color:$gray-100;
    }

    &.root{
      margin-left:6px;
    }


  }

  .rule-descriptors{

    .descriptor{
      display:inline-block;
      font-size:0.5rem;
      color:$gray-600;
    }
  }


  .rule{
    color:#373667;
    font-size:0.9rem;
    background-color: $white;
    width:100%;
    padding:6px 0;
    margin:1px 0 0 2px;
    line-height:1.2em;
    vertical-align:middle;
    cursor:pointer;
    border-bottom:1px solid $gray-200;
    border-left:5px solid transparent; // invisible border for alignment with special left border indicators like is-standalone

    &.account-rule{
      color:#fff;
      background-color: #18cbbc;
    }
    &.is-selected{
      background-color: lightyellow!important;//(overrides is-match)
      .icon-and-label svg{color:$primary;}
      .rule-tree-label{color:$primary;}
      .bid-pass{
        border-color:$gray-200;
      }
    }

    &.is-standalone{
      border-left:9px solid $blue-200;
      padding-left:2px;
      .icon-standalone {
        position:absolute;
        top:32%;
        left:4px;
        color:$white; //$blue-200;
      }

    }

    &.is-match{
      background-color: rgba(250, 212, 207, 0.45);//pink,
    }

    &.is-not-match{
      //In tree filtering we show parent rules when children match, so hierarchy is not lost
      //This class fades the parent to indicate it isn't a match
      opacity:0.6;
    }

    &.flow-include{
      .rule-tree-label{
        color: #2e811c;
      }
    }
    &.flow-exclude{
      .rule-tree-label{
        color:darkred;
      }
    }

    //&.filtered-count{  //feature disabled, see TreeProvider:getMatches()
    //  display:none;
    //  margin:0.25rem 0 0.5rem 18px;
    //  font-size:0.9rem;
    //  color:$gray-500;
    //  cursor:default;
    //  &:hover{
    //    background-color: $white;
    //  }
    //}

    &:hover{
      background-color: $table-hover-bg;
      .bid-pass{
        border-color:$white;
      }
    }


    .icon-rule{
      margin-right:6px;
      display:inline-block;
      &.bid-block{ color:#990000;}
    }


    &.disabled, &.is-not-match{
      color:$gray-500;
      .icon-rule {
        color: $gray-300;
      }
      .icon-disabled {
        color: red;
      }
      .rule-tree-label{
        color: $gray-500;
      }
    }

  }

  .icon-and-label{
    align-items: center;
  }

  .rule-tree-label{
    flex-grow:1;
    cursor:pointer;
    font-size:1em;
    font-weight:500;
    color:$gray-700;
    .child-count {
      color:$primary;
      font-size:0.7rem;
    }
  }


} /*.col-rule-tree*/


//Contains the rule's TabContent, TabPanes, all forms and widgets
.rule-config {
  // Encapsulation of a single integration's settings applied to a rule
  .integration-container {
    margin-bottom:2rem;
    min-width:600px;
    border:1px solid $gray-300;
    background-color: $gray-100;
    border-radius: 4px;
    padding:1rem;
    .header{
      margin-bottom:1rem;
    }
    h5{font-size: 0.9rem;margin:0;}
    .actions {
      line-height:1.2rem;
      .btn {padding-top:0;padding-bottom:0;}
    }

    form{
      label{
        display:block;
        //text-align:right;
      }
      .form-group{
        margin-bottom:1rem;
      }
    }

    .detail-row{
      border-bottom:1px solid $gray-200;
      padding:0.4rem 0;
      &:last-child{
        border-bottom:none;
      }
      .form-group{
        margin-bottom:0.5rem;
      }
      label{
        flex-basis:160px;
        color: $gray-700;
      }
      .description{
        // flex-grow:1;
      }
      .value{
        flex-grow:1;
        margin-left:1rem;
      }
    }

    &.proxy_bidding, &.enrichment{
      margin-bottom:0;
    }

  }

  .activity-schedule{
    .flatpickr-wrapper{
      display:block;
    }
    .schedule-affects-siblings-example{
      label{
        font-size:0.9rem;
      }
      .example-tree{
        border:1px solid $gray-200;
        border-radius:4px;
        padding: 1rem;
        background-color:$gray-100;
        .item{
          display:block;
          width:100px;
          padding:0.2rem 0.3rem;
          font-size:0.8rem;
          border:1px solid $gray-400;
          margin-bottom:6px;
          background-color:$white;
          &.ms-1{
            margin-left:20px!important;
          }
          &.ms-2{
            margin-left:40px!important;
          }
          &.ms-3{
            margin-left:60px!important;
          }
          &.affected{
            background-color:limegreen;
          }
        }
      }

    }
  }

  //card boxes on summary page
  .rule-summary{

    .summary-item{

      border:1px solid $gray-200;
      box-shadow: 1px 1px 1px 0 rgba(90, 90, 90, 0.20);
      margin:6px;

      .summary-item-label{
        font-weight:bold;
        padding:0;
        svg{
          color: $primary;
        }

      }

      .summary-item-data{
        position:relative;
        font-size:0.9rem;
        padding:6px;
        ul{
          margin:0;
          padding:0;
          list-style-type: none;
          li{ margin: 8px 0; line-height:1rem;}
        }
      }


      &.bid-summary{
        flex-basis:240px!important;
        flex-grow:0;
        .bid-modifier-arrow{
          display:inline-block;
          font-size:2em;
          margin:4px 5px 0 0;
          color:green;
          &.sub-100{color:darkred}
        }
        .rule-bid{
          display:inline-block;
          font-size:1.6rem;
          &.bid-block, &.bid-pass{ font-size:1.6rem;}

        }
      }


      &.target-summary, &.enrichment-summary{
        //flex-basis:400px!important;

        ul{margin-bottom:0;}
        .target-description{
          line-height:1.4rem;
          margin-bottom:0.25rem;
          svg{color: $gray-500;}
          .target-summary-label{background-color: #deffd9;padding:1px 3px; border:1px solid #ddd}
          .target-match-bool{font-weight:500;}
          .target-is{color:#000099;}
          .target-is-not{color:#990000;}
        }

        #expression-toggler{
          position:relative;
        }
        .target-summary-group-or{
          //margin-left:50px;
          color:$gray-600;
          margin:2px 0;
          font-weight:500;
        }
        h5{font-size:1em;}
      }

      &.activity-schedule-summary{
        li{font-size:0.9em;}
      }



    }
    /* Extend last item full width? .summary-item:last-child{ flex-grow:1;}*/
  } /* rule-summary */

  .sales-condition{

    .card-header{

      border-bottom:0;

      h4{ font-size:1.2rem;}
      .btn{
        margin-top:-2px;
      }

    }
    legend{
      font-size:1rem;
      color:#555;
      font-weight:600;
    }
    .targeting-manager{
      width:100%;
    }

    .card-body{
      padding-top:0;
    }

    // applies to both enrichment settings AND integration settings within in the enrichment
    .detail-table{

      .detail-row{
        border-bottom:1px solid $gray-200;
        padding:0.5rem 0;
        &:last-child{
          border-bottom:none;
        }

        label{
          color: $gray-700;
          font-weight:600;
        }

        .value{
          flex-grow:1;
          //in display mode keep text vertically centered
          display:flex;
          align-items: center;
        }


      }
    }
  }

} //.rule-config

.col-rule-config {
  position: relative;

  .actions-panel{
    position:absolute;
    top:0;
    bottom:0;
    width:325px;
    background-color: $white;
    padding:1rem;

    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    visibility: hidden;

    &.open{
      visibility: visible;
    }

    .header{
      color:$gray-700;
      h5{margin-bottom:0.3rem;}
    }

    .dropdown-menu{
      .dropdown-item{
        &.disabled{
          color:$gray-300;
        }
      }
    }


    .rule-action-menu{
      .rule-action-item{
        margin:0.25rem 0;
        .btn{
          svg{ margin-right:0.75rem;}
        }
      }
    }

  }

  .actions-overlay {
    position: absolute;
    top: 0;
    left:0;
    border-radius: 0.25rem; //matches .card radius
    height:1px;
    width:1px;
    z-index: 1000;
    //background: rgba(0, 0, 0, 0.25); //darken
    background: rgba(255,255,255,0.7); //lighten
    opacity: 0;
    // visibility: hidden;
    -webkit-transition: opacity 0.8s, width 0.1s 0.5s, height 0.1s 0.5s;
    transition: opacity 0.8s, width 0.1s 0.5s, height 0.1s 0.5s;

    &.open {
      opacity:1;
      width:100%;
      height:100%;
      bottom:0;
      right:0;
      -webkit-transition: opacity 0.8s;
      transition: opacity 0.8s;
    }
  }

  .rule-header{
    display:flex;
    align-items:baseline;
    justify-content: space-between;

    .rule-name{
      h5{
        margin-bottom:0;
        font-size:1.2rem;
      }

      &.label-edit{
        input{
          min-width:350px;
          border:0;
          border-bottom:1px solid $gray-600;
          border-radius:0;
          font-size:1.1rem;
          font-weight:500;
          line-height:1;
          &:focus{
            box-shadow:none;
          }
        }
      }

    }
  }


  .rules-config-section {

    .tab-content {
      min-height: 50vh;

      &.rules-transition {
        animation-name: enterRulesConfig;
        animation-duration: 500ms;
        animation-timing-function: linear;
      }
    }

  }

  @keyframes enterRulesConfig {
    from {
      opacity: 0;
      transform: translateX(5px);
    }
    to {
      opacity: 1;
    }
  }


  .page-card {
    padding-top: 20px !important;
  }

  .rule-none-selected {
    height: 10em;
    padding-top:1em;
    font-size: 1.2em;
    font-weight: bold;
    color: $gray-600;

  }






}/* .col-rule-config */



#batch-rule-attribute-select{
  width:300px;
}

#batch-rule-values{
  width: 100%;
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height:150px;
  &.lots {
    max-height:270px;
  }
  &.few {
    width:50%;
  }
}

// Tab row is very wide and will wrap even at large viewports.
// Try to manage it here (might be better to hide some of the options in a flyout menu)
@media (max-width: 1560px) {
  .mastodon-tabs {
    .nav-link, .btn-link {
      padding: 1.4rem 0.9rem;
      //font-size:1rem;
    }
  }
}

@media (max-width: 1475px) {
  .mastodon-tabs {

    .nav-link {
      //padding: 0.8rem 0.9rem !important;
      padding:1.4rem 0.7rem 0.5rem 0.7rem!important;
      font-size:0.9rem;
    }

  }
}
