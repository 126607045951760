@import "../../../Layout/common";
/*
 * Styles specific to advertiser's target rules
 * Use with shared styles in /components/rules/styles
 */

#target-rule-manager{

  .order-info-section{
    .card-body{padding: 1rem;}
    .order-info-cell{
      margin-right:4rem;
      label{
        margin-bottom: 0;
      }
    }

  }

  .order-dashboard {
    .tile-grid {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 12px;
    }
  }

  .col-rule-tree {
    //min-width: 400px;
    //.page-section{
    //  min-width:380px;
    //}
    .card-body{
    min-height:80vh;
    }
  }

  // Layout area, contains rule header, tab nav, and content (.rule-config)
  .col-rule-config {
    padding-left: 18px; // space between tree and rule-config sections

    // Panel for advertiser rules
    // originally we applied a transform that had it slide in from right,
    // with overflow-x:clip on .rules-config-section to hide it when closed.
    // However the clipping, despite being on x-axis, would clip off some dropdowns
    // if they went over the bottom edge of rules-config-section.
    // Behavior changed to fade-in.

    .actions-panel{
      right:0;
      opacity:0;
      border-left:1px solid $gray-300;
      box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.14);
      z-index:1001;

      // -webkit-transform: translate3d(100%, 0, 0);
     // transform: translate3d(100%, 0, 0);


      &.open{
       // -webkit-transform: translate3d(0, 0, 0);
       // transform: translate3d(0, 0, 0);
        opacity:1;
      }
    }

    .actions-overlay{
      z-index: 1000;
    }

  }

  //Contains the rule's TabContent, TabPanes, all forms and widgets
  .rule-config{
    #rule-bidding{
      .bid-alert {
        margin-top:20px;

        .value-delta{
          font-size:1.1rem;
          font-weight:600;
          &.overflow{
            color:darkred;
          }

        }

      }
    }

    #rule-budgets-form{
      .row{
        margin: 0 2em;
        padding: 1em 1em;

      }
      .budget-type{
        font-size:1.2em;
        font-weight:600;
        margin-right:35px;
      }
    }

    .pixel-form{
      padding:0.6rem 1.6rem;
    }

    #rule-tagger{
      display:flex;
      .tag-error{
        flex-grow:1;
      }
      .tag-container{
        display:flex;
        height: 30px;
        width: auto;
        min-width:40px;
        border-radius: 4px;
        position: relative;
        background: #f5de94;
        text-align:center;
        line-height:24px;
        margin:4px;
        font-weight:700;
        color:$gray-500;
        font-size:1.1rem;

      }
    }



    .sip-configuration{
      margin-top: 1rem;
    }


    //Ad Select
    .rule-ad-form{

      .rule-ad-list{
        .ad-row{
          margin-bottom:1em;
          //border-bottom:1px solid $gray-200;
          .ad-col{
            background-color: $gray-200;
            border:1px solid $gray-300;
            border-radius:6px;
            padding:0.5rem;
            &.selected {
              background-color: $blue-100;
            }
          }
          .ad-select{
            flex-basis:60px;
            padding-top:30px;
          }
          .ad-name{
            margin:0.25rem 0;
            font-weight:bold;
          }
          .ad-display{
            border:1px solid $gray-300;
          }
        }
        }


    }

    .channel-modifiers{


      .channel-modifiers-table{

        .name-cell{
          color: $gray-700; //light color by default, darker if active


          .channel-tag{
            display:inline-block;
            background-color:$gray-700;
            border-radius:4px;
            color:$white;
            font-size:0.9rem;
            margin-left:0.5rem;
            padding:0 0.5rem;
            font-weight:600;
            margin-right:0.5rem;
          }

          .inactive-channel{
            background-color:$gray-700!important;
          }


          .blocked{
            .channel-name{
              color:darkred;
            }
            .channel-tag{
              background-color:darkred;
            }
          }
        }
      }
    }



    #datasheets{
      transition:opacity 500ms ease-in-out 200ms, height 100ms ease-in-out 0ms;
      .datasheet-row{
        border:1px solid $gray-400;
        border-radius:4px;
        padding:1rem;

        .name{ font-weight:600;}
        .info {
          color:$gray-600;
          font-size: 0.9rem;
        }
        .icon{ color: $gray-500;}
        &.attach{
          background-color:#fafcfd
        }
      }

      &.hide{
        opacity:0;
        height:0;
        overflow:hidden;
      }


    }

    #mng-datasheets{

      opacity:0;
      height:0;
      overflow:hidden;
      transition:opacity 500ms ease-in-out 200ms, height 100ms ease-in-out 0ms;
      font-size:0.9rem;
      &.show{
        opacity:1;
        min-height:300px;
        height:auto;
      }


      .datasheet-versions-subtable{
        margin-top:0.5em;
        //padding:0.6rem;
       // margin:0.4rem 2rem 0 2rem;
        background-color: #fff;//$gray-100;
        border:1px solid $gray-300;
        border-radius:4px;

      }

      .action-item .btn{
        padding:0;
        font-size:0.9rem;
      }

    }

    //In advertiser rules, summary items are displayed as flex-wrapped grid of boxes
    .rule-summary{
      display:flex;   //for advertiser
      flex-wrap:wrap;  //for advertiser, will wrap

      .summary-item {
        flex-basis:50%; //300px;
        flex-grow:1;
        flex-shrink:0;



        &.channel-mod-summary{
          .scroll-list {
            max-height:250px;
            padding:0.8em;
            overflow-x:hidden;
            overflow-y:auto;
          }
          .data-row label { min-width:300px}

        }

        &.budget-summary{
          .caps-table{
            .caps-row > div {
              flex-basis: 33%;
            }
          }
        }

        &.ads-summary {
          flex-basis: 100%;
          .ad-display {
            margin-top:0.8em;
            border: 1px solid $gray-300;
            border-radius:6px;
            padding:0.8em;
          }
        }
        &.destination-summary {
          flex-basis: 100%;
        }

        &.bid-schedule-summary{
          flex-basis:100%;
          #summary-schedule-legend{
            display:flex;
            justify-content:space-evenly;
            font-size:0.8em;
            div{vertical-align:middle;}
          }
          .summary-schedule-row{
            display:flex;

            div:first-child{
              flex-basis:40px;
            }

          }
          .summary-hour-cell{
            display:inline-block;
            width:8px;
            height:10px;
            margin-left:1px;
            border:1px solid #ccc;

            &.up{background-color: #95ee94;}
            &.down{background-color: #ee7c76;}
            &.off{background-color: #555;}

          }


        }
      }


    }
  }
} /* #target-rule-manager */


