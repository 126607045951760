@import "../../Layout/common";


.account-settings{
  keeper-lock{
    display:none;
  }

}

/* ads list */
.ads-table{
  .td.ad-cell{
      .ad-display{
        background-color:$white;
        padding:1rem;
        border:1px solid $gray-300;
        white-space: initial; //overrides ReactTable cell white-space nowrap
      }
    }
}

/* Account/orders list */
.accounts-table {

    margin-top:1.2rem;
    .td.account-cell{

      border-right:none!important;
      border-bottom: 1px solid $gray-400;

      .account-name{
        font-weight: 600!important;
        margin-bottom:0.1rem;
      }



        .list-actions{
          .action-item{
            margin-right:0;
            a:hover{color:$darkerblue;}
            &:last-child{
              .bull-delim{
                display:none;
              }
            }
          }
        }

        .bull-delim{
          margin:0 .8em;

        }

        .accounting{
          //display:none!important;
          .bal-alert{
            margin-right:1rem;
            font-weight:700;
            svg{
              color:darkred;
            }
          }

          .bal-pod{
            display:flex;

            font-size:0.85rem;
            font-weight:600;
            color: $gray-600;

            .type{
              padding:2px 4px;
              color:$white;
              background-color:#85b579;
              border:1px solid $gray-400;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;

            }
            .bal{
              padding:2px 4px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              min-width:65px;
              overflow:hidden;
              text-align:right;
              border-top:1px solid $gray-400;
              border-right:1px solid $gray-400;
              border-bottom:1px solid $gray-400;
            }

            &.bal-alert .type{
               background-color:darkred;
            }

            //grayed out balance pod if no orders
            &.no-orders{
              .type{
                background-color:$gray-100;
                color:$gray-400;
              }
              .bal{ color:$gray-400;}
            }

            .btn{
              font-size:0.75rem;
            }
          }
        }

    }


  .subcomponent{
    //Undo styles of standard subcomponent (ReactTableFlex.scss, ReactTableGrid.scss)
    padding:0;
    box-shadow: none;
    background-color:transparent;
    border-bottom:none!important;
    &:hover{
      //disable default row hover
      background-color:transparent!important;
      box-shadow: none;
    }
    // ------------------------
    margin-bottom: 1rem;

    .table-container {
      padding-bottom:0;
    }
    .table{
      margin-bottom:0;
      background-color: transparent;
      border:none!important;
      border-radius:0;
      padding-left:2rem;
      //stale last sold date column
      .stale{
        color:darkred;
        font-weight:600;
      }

      .row-wrapper:last-child{
        .td{border-bottom:none!important;}
      }
      .order-name-cell a{
        font-weight:500;
      }
    }
    .btn-fav-show {
      margin:0 0 0 3rem;
      padding:0;
    }
  }

} //.accounts-table

/* GOALS on account settings page */

.goal{
  &.new{background-color: #fafcfd!important;}
  .card-title{
    text-transform: none!important;
    color:$gray-700;
  }
  .card-title .value{ color:$blue;display:inline-block;}
  .card-body{
    padding:0.8rem;
    code{font-size:0.8rem;}
  }

}

.goal-tracking-table{
  font-size:0.9rem;
  .file-name{
    font-weight:500;
  }
  .file-size{
    color:$gray-600;
    font-size:0.8rem;
  }
}

#goal-tracking-modal{
  #header-example-header{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap:4px;
    grid-gap: 4px;
    //background-color:$gray-200;
    font-weight:500;

  }
  #header-example{
    color:steelblue;
    font-size:0.9rem;
    margin-bottom:1em;
    display:grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap:4px;
    grid-gap: 4px;
    div{
      border:1px dashed steelblue;
      padding:0.5rem;
      &.req{
        border-color:red;
        color:red;
      }
    }
  }
}

#post-goal-help-modal{
  font-size:0.9rem;
  table{
    border-collapse:collapse;
    margin-bottom:2em;
    // color:$gray-700;
    tr{
      border-bottom: 1px solid $gray-200;
      td, th{
        padding:0.5rem 1rem;
      }
    }
  }
}

/* FUNDS on account settings page */
#manage-funds-section{


  .accounting-details{

    .account-balance{
      flex-basis:40%;
    }

    .payment-plan{
      flex-basis:60%;
    }
    .card-body{
      padding:1rem;
    }

    .card-header{
      padding-bottom:0;
      background-color:transparent;
      border-bottom:none;
      font-weight:600;
    }

    .balance{
        font-size:24px;
    }

      //flex-basis:70%;
      background-color:#fafafc!important;
      .standard-alert svg{ color:$red;}

      .detail-table{
        background-color:$white;
        border-radius:4px;
        padding:1em;
        .detail-row{
          display:flex;
          justify-content: space-between;
          align-items: center;
        }

        .detail-row{
          border-bottom:1px solid $gray-200;
          padding:0.4rem 0;
          &:last-child{
            border-bottom:none;
          }
          .form-group{
            margin-bottom:0;
          }
          label{
            //flex-basis:80px;
            margin-right:2rem;
            margin-bottom: 0;
            // color:$gray-600;
          }
          .description{
            flex-grow:1;
            small{display:block;
              line-height:1rem;
            }
          }
          .value{
            flex-basis:160px;
            margin-left:0.5rem;
            text-align:right;
          }
        }
      }

  }


  .cc-masked{
    .mask {
      color: $gray-500;
    }
  }



  #cards-on-file{

    //.drag-drop-zone{
    //  //Possible chrome bug, if dragEnd event manipulates display, it will fire too soon after dragStart.
    //  //So here we use opacity instead
    //  opacity:0;
    //
    //  &.active{opacity:1;}
    //  &.in-zone{
    //    background-color:lightyellow;
    //    border:1px dotted red;
    //  }
    //}

    .card-on-file-row{
      position:relative;
      align-items:center;
      padding:0.8rem;
      border-bottom:1px solid $gray-200;
      &:last-child, &.new-card {border-bottom:none;}
      //&:hover{background-color: $gray-100};
      &.pending{color:$gray-500}
      &.selected{background-color: rgba(83, 185, 249, .05)};
      .cc-priority-col {
        flex-basis:50px;
        margin-right:1em;
        cursor:pointer;
        svg{
          color:$gray-500;
          &:hover{
            color:darkorange;
          }


        }
      }
      .cc-image-col{ flex-basis:60px;margin-right:1em;}
      .cc-number-col{
        flex-basis:160px;
        .btn-add-card{
          //padding-left:0;
        }
      }
      .cc-exp-col {flex-basis:120px;}
      .cc-auto-pay-col{
        flex-basis:200px;
        margin-left:3em;
        label{font-weight:normal;}
      }
      .cc-checkout{
        flex-basis:360px;
        .form-inline{display:inline-flex;}
        .btn-outline-secondary{
          //set background color so it stays white when row is highlighted (keep text color unchanged);
          background-color: #fff;
          &:hover{
            color:initial;
          }

          //border-color: $gray-400;
        }
      }

      .cc-add-billing-col {
        flex-grow:1;
      }
      .cc-pending-col{
        flex-basis:400px;
        color:$gray-600;
      }

      .expired{
        color:darkred;
        svg{vertical-align: baseline;}
      }
      .edit-card{
        //background-color:#fcfcfa;
      }

      &.new-card{
        .cc-checkout{
          flex-basis:300px;
        }
      }
    }
  }

  .transactions-table{
    font-size:0.9rem;
    .amount-cell{
      .refund, .negative{color:$red;}
    }
    .list-actions{
      text-align:right;
      white-space: initial!important; //override react table no-wrap
    }
    .status-cell{
      white-space: initial!important; //override react table no-wrap
    }
  }

  /* Form for entering a new credit card NOT USING FOR NOW */
  //.cc-input{
  //  display:inline-flex;
  //  .cc-input-form{
  //
  //    input.form-control{
  //      height:auto;
  //      border:unset;
  //      padding:unset;
  //      outline:unset;
  //      font-size:inherit;
  //      &:focus{
  //        box-shadow:none;
  //        border:unset;
  //      }
  //
  //    }
  //    .inp-card-number{
  //      width:16rem;
  //    }
  //    .inp-card-exp{
  //      width:4rem;
  //    }
  //
  //  }
  //}
}
