@import "../../../Layout/common";
@import "../../../Components/Rules/icons";


.routing-map {
  /* "Fake" top scrollbar solution: https://stackoverflow.com/questions/3934271/horizontal-scrollbar-on-top-and-bottom-of-table */
  .top-scroll{
    width:100%;
    height:20px;
    overflow-x:scroll;
    overflow-y:hidden;
    div {
      height:20px;
      width: 1700px;
    }
  }


  .hz-scroll {
    position:relative;
    height:100%;
    overflow-x:auto;
    overflow-y:hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .map-area {
    position: relative;

    background-color: #f6f6f6;
    border:1px solid $gray-300;
    // we will dynamically adjust height with javascript to minimize the need to scroll
    min-height: 450px;
    width:1700px;

    .loader-container {
      position:absolute;
      z-index: 500;
      right:0;
      bottom:0;
      width:100%;
      height:100%;


      .svg-loader-container {
        top:35%!important;
      }
    }

    .node-col {
      position:absolute;
      width:340px;
      top:25px;
      &.sources{
        left:25px;
      }
      &.eligibility{
        left: 405px;
      }
      &.channels{
        left:785px;
      }
      &.orders {
        left: 1170px;
       // width:400px;
      }

      //Keep it above the svg connectors
      z-index: 1;

      .node-display {


        .caption {
          font-weight:600;
          color: $gray-700;
        }

        .node-header{
          height: 30px;
          .node-count{
            margin-left:0.25rem;
          }
        }
        .node-list {
          //margin-right: 40px;

          .node-row {
            margin-bottom: 10px;
            min-height:80px; // consistent height might help with connector alignment ?

            // Styles when hovering over a node (and applies to all related nodes)
            &.related:hover, &.related {

              .node-pod {
                background-color: #ffecce; //rgba(255, 165, 0, 0.22);
                border-color: orange;
              }

              .node-pod-footer {
                border-color: orange;
                background-color: #fff3e4;
              }

              &.selected {
                .node-pod-footer {
                  background-color: aliceblue !important;
                }
              }

            }

            &.selected{
              .node-pod {
                background-color: $primary!important;
                color:$white;
                border-color: steelblue!important;
                .btn {
                  color: $white;
                }
              }

              .node-pod-footer {
                border-color: steelblue!important;
              }

            }

            .node-pod {

              background-color: aliceblue;
              border-top: 2px solid $gray-400;
              border-left: 2px solid $gray-400;
              border-right: 2px solid $gray-400;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;



              //cursor: pointer;

              .node-pod-header {
                .node-pod-title {
                  display:flex;
                  justify-content: space-between;
                  font-weight:600;
                }

                padding: 0.25rem;
              }
              .actions {
                white-space: nowrap;
                min-width:50px;
                text-align: right;
                .btn {
                  padding:0;
                  margin:0 4px;
                }
              }



              .id{
                display:inline-block;
                font-size:0.6rem;
                line-height:0.7rem;
                min-width: 18px;
                padding: 2px;
                border:1px solid $gray-300;
                border-radius: 4px;
                color: $gray-700!important; // no change in pod hover mode
                background-color: $white;
                vertical-align: middle;
                text-align: center;
                margin:0 3px;
              }
            }

            .node-pod-footer {

              background-color: $white;
              font-size: 0.8rem;
              padding: 0.25rem;
              border-top: 1px solid $gray-300;
              border-bottom: 2px solid $gray-400;
              border-left: 2px solid $gray-400;
              border-right: 2px solid $gray-400;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              display:flex;

              div {
                position:relative;
                &:first-child {
                  border-right: 1px solid $gray-300;
                }
                padding:4px;
                //text-align: center;
                flex-basis:50%;
                label {
                  position:absolute;
                  top:1px;
                  left:4px;
                  font-weight: 600;
                  font-size:.7rem;
                }
                time {
                  display:block;
                  margin-top:10px;
                }
              }
            }
            .node-expand {
              padding:0.25rem;
              font-size: 0.8rem;
              background-color: $white;
              .rules{
                margin-left:0.5rem;
                .rule {
                  border:1px solid $gray-300;
                  border-radius: 4px;
                  background-color: $white ;
                  padding:0.4rem;
                  margin:6px 0;
                }
              }

            }

          }
        }

      }


    }



  }

}
