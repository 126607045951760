@import "../../../Layout/common";

.rules-modal{
  .modal-content{
    //min-height:500px;
    height:90vh;
    overflow:hidden;
    //background-color:#fcfdfd;
  }

  .modal-body{
    //height and overflow critical for auto scrolling and footer placement inside modal
    height:100%;
    overflow:hidden;
    //background-color:#fcfdfd;

    .loading-bar-container{
      z-index:950;
      top:0;

      .loading-bar-overlay{
        top:6px;
        z-index:951;
      }

      .loading-bar-message{
        z-index:955;
      }
    }

    .rules-manager-container{
      height:100%;
      .svg-loader-container{
        z-index:5000;
      }
    }
  }

  .col-rule-tree{
    min-width:350px;
    display:flex;
    flex-direction: column;
    z-index:900;// allows rule action menu to slide under
    padding-right: 0.5rem;

    // moved to styles.scsss
    margin-right:1rem;
    border-right: 1px solid $gray-200;

    background-color: $content-bg;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    .tree-header{
      min-height:50px;
      //background-color:$white;
      width:100%;
      margin-bottom:0.5rem;
      border-bottom:1px solid $gray-200;
      padding:0.5rem;
    }
    .tree{
      overflow-y:auto;
      overflow-x:hidden;
    }


  }

  .col-rule-config{
    position:relative;
    display:flex;
    flex-direction: column;
    //background-color:#fcfdfd;
    //shrink the text down
    //font-size:0.9rem;
    .form-text{
      font-size:90%;
    }

    .tab-content{
      //prevents horizontal scrollbar from appearing
      width:96%;

      .tab-pane{
        padding-left:0.3rem;


      }
    }

    //A bit different from advertiser rules #rule-header, which just has .rule-name
    .rule-header{
      .btn-sm{
        font-size:0.875rem!important;
      }

      .rule-title{
        h5{
          margin-bottom:0;
          font-size:1.1rem;
          .rule-name{
            color: $primary;
            font-size:1rem;
            font-weight:700;
          }
        }

        &.label-edit{
          input{
            min-width:350px;
            border:0;
            border-bottom:1px solid $gray-600;
            border-radius:0;
            font-size:1.1rem;
            font-weight:500;
            line-height:1;
            &:focus{
              box-shadow:none;
            }
          }
        }
      }
    }

    .rule-config{
      margin-bottom:52px;
      overflow-y:auto;
      height:100%;
    }

    .footer{
      position:absolute;
      bottom:0;
      height:52px;
      padding:0.35rem;
      margin-top:0.3rem;
      border-top: 1px solid $gray-200;
      //background-color:#fff;
      width:100%;
      text-align:right;
    }

    //Styles are also in #col-rule-form  (with wrapping styles)
    //card boxes on summary page
    .rule-summary{

      .summary-item{
        display:block;
        margin-bottom:1rem;


        &.bid-summary{
          flex-basis:175px;
          flex-grow:0;
          .bid-modifier-arrow{
            display:inline-block;
            font-size:2em;
            margin:4px 5px 0 0;
            color:green;
            &.sub-100{color:darkred}
          }
          .rule-bid{
            display:inline-block;
            font-size:1.6rem;
            &.bid-block, &.bid-pass{ font-size:1.6rem;}

          }
        }

        &.target-summary, &.enrichment-summary{
          flex-basis:330px;
        }

        &.activity-schedule-summary{
          li{font-size:0.9em;}
        }


      }
      /* Extend last item full width? .summary-item:last-child{ flex-grow:1;}*/
    } /* rule-summary */
  }//.col-rule-config

}

