@import "../../Layout/common";

//Styles for publisher posting docs
.posting-instructions {
  h2 {
    font-size:21px;
  }
   h3 {
    font-size: 18px;
   }
   h4 {
    font-size: 14.4px;
   }
  .table {
    thead {
      background-color: #EAF3FF;
    }

  }

  .example-col {
    .sale-step-num {
      display:inline-block;
      line-height:28px;
      width:38px;
      height:38px;
      border-radius:19px;
      border:3pt solid $gray-700;
      text-align:center;
      color:$gray-700;
      font-size:1.2rem;
      font-weight:700;
    }

    .tab-pane.active {
      background-color: $body-bg;
      padding:0.75rem;
    }
    .nav-link {
      color: $gray-700;
      &.active {
        color:$link-color;
        background-color: $body-bg;
        border-bottom-color:$body-bg!important;
      }
    }
    .hljs, .curl {
      background-color: $white;
    }
  }


  .phase-examples {
    .nav-link {
      color:$gray-600;

      &:hover {
        color:$primary;
      }
      &.active {
        color:$primary;
      }
    }

    //.btn-group .btn {
    //  text-transform: none;
    //}
  }
}
